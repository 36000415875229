/**
 * Direttiva che costruisce il corpo della barra di ricerca
 */
import { Item, User, SenecaResponse, UserGroup } from "atfcore-commonclasses";

export interface ISearchBarBodyDirectiveScope extends ng.IScope {
	libraryApplicationData: any;
	filterKey: string;
	currentFilter: any;
	filterName: any;
	searchBarManager: any;
	isCompetenceFilter: boolean;
}
angular.module('app').directive("searchBarBody", (LibraryApplicationData, GlobalApplicationData, SearchBarManager) => {
	return {
		restrict: 'E',
		transclude: true,
		scope: {
			setLibraryFilter: '=',
			unsetLibraryFilter: '=',
			filterName: '@',
			filtersContent: '=',
			currentFilter: '='
		},
		link: link,
		templateUrl: 'app/shared/searchBar/searchBarContent/searchBarBody/searchBarBody.html',
		controller: ($scope: ISearchBarBodyDirectiveScope) => {
		}
	};
	function link($scope: ISearchBarBodyDirectiveScope, element: JQuery, attrs: ng.IAttributes) {
		// Collegamento all'oggetto principale
		$scope.libraryApplicationData = LibraryApplicationData;

		// Collegamento al servizio che gestisce i filtri
		$scope.searchBarManager = SearchBarManager;

		// Verifico se il filtro corrente è quello delle competenze (quindi lista di tutti i tech + soft)
		$scope.isCompetenceFilter = $scope.currentFilter && $scope.currentFilter.code && $scope.currentFilter.code === $scope.libraryApplicationData.constants.SOFT_AND_TECH_SKILLS ? true : false;

	}
});